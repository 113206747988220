import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
// import ReservationForm from "../components/globals/ReservationForm"

import img from "../images/bcg/easter-banner.jpg"

const EasterPage = ({ location }) => (
  <Layout>
    <SEO 
      title="Easter at Traditions" 
      desc="Easter Buffet on Lake Hartwell in Clemson, SC"
      img={img}
      url={location.href}
    />
    <AboutHeader img={img}>
      <BannerLeft title="Easter Buffet" subtitle="Reservations Required.">
        <hr />
        <p>
          Join us for an Easter Eggstravaganza!
        </p>
      </BannerLeft>
    </AboutHeader>

    <section id="special">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Easter Buffet Details</h2>
          <br />
          <p>
            Join us on the lake! Reservations are required this year, so please call the number below and we'll save you a seat.
          </p>
          <br />
          <p>
           April 17, 2022
           <br/>
           11 a.m. to 2:30 p.m.
           <br/>
           Adults $28
           <br/>
           Seniors $26
           <br/>
           Kids 4-12 $16
          </p>
          <br />
        
          <p>
          </p>
          <div className="row px-0 justify-content-center catering-form" >
            <BannerButton>
              <a href="/easter-menu.pdf" rel="noopener noreferrer">Easter Menu</a>
            </BannerButton>
          </div>
        </div>
      </div>
      
      <br />

      <h2>Call for Reservations</h2>
      <div className="row px-0 justify-content-center catering-form" >
        <BannerButton>
          <a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a>
        </BannerButton>
      </div>
      <br />
      <br />
      <br />
      <br />
    </section>
  </Layout>
)

export default EasterPage
